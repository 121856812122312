import React from 'react';
import { RegularLink } from 'components/Shared/Link/Link';
import './LocationCard.scss';


const Address = ({ line1, line2}) => (
  <React.Fragment>
    <div className="location-card__address" dangerouslySetInnerHTML={{__html: line1}} />
    <div className="location-card__address" dangerouslySetInnerHTML={{__html: line2}} />
  </React.Fragment>
);

const LocationCard = ({ data }) => (
  <div className="location-card">
    <div className="location-card__top">
      <div className="location-card__office" dangerouslySetInnerHTML={{__html: data.officeName}} />
      { data.mapLink ? (
        <RegularLink className="location-card__address-link" to={data.mapLink}>
          <Address line1={data.addressLine1} line2={data.addressLine2} />
        </RegularLink>
      ) : (
        <Address line1={data.addressLine1} line2={data.addressLine2} />
      ) }
    </div>
    <div className="location-card__phone">
      <span className="location-card__phone__title">Phone:</span>
      <RegularLink className="location-card__phone__text" to={`tel:${data.phone.link}`}>
        <div dangerouslySetInnerHTML={{__html: data.phone.title}} />
      </RegularLink>
    </div>
    { data.email ? (
      <div className="location-card__email">
        <span className="location-card__email__title">Email:</span>
        <RegularLink className="location-card__email__text" to={`mailto:${data.email}`}>
          {data.email}
        </RegularLink>
      </div>
    ) : null }
  </div>
);

export default LocationCard;