import React, { Component } from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import LocationCard from 'components/LocationCard/LocationCard';
import './LocationsContainer.scss';

const LocationCards = ({ cards }) => {
  let counter = 0;
  if(cards) {
    return cards.map((card, i) => {
      return (
        <div
          key={i}
          className={`locations-container__card-box col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-5 ${++counter%2 ? 'offset-lg-1' : ''}`}
        >
          <LocationCard data={card} />
          <SeparatorWithGradient className="locations-container__separator"/>
        </div>
      );
    });
  }
  return null;
}

class LocationsContainer extends Component {

  constructor(props) {
    super(props);
    this.tags = ["All"];

    props.data.branches.sort().map(branch => {
      const tag = branch.tag;
      if (this.tags.indexOf(tag) === -1) {
        this.tags.push(tag);
      }
    });

    this.state = {
      tagFilter: new Set()
    };
  }

  filter = (value, checked) => {
    let tagFilter = this.state.tagFilter;
    if (checked) {
      if (value) {
        tagFilter.add(value);
      } else {
        tagFilter.clear();
      }
    } else {
      tagFilter.delete(value);
    }
    this.setState({ tagFilter });
  }

  render() {
    const { branches, notice } = this.props.data;
    const { tagFilter } = this.state;
    return (
      <section className="locations-container">
        <ContentBox>
          <div className="locations-container__filter col-12 col-lg-10 offset-lg-1">
            <div className="locations-container__filter__tags-box">
              { this.tags.map((tag, i) => {
                const key = `filter_tag_${i}`;
                const checked = tagFilter.size ? tagFilter.has(i) : !i;
                return (
                  <div
                    className="locations-container__filter__tag"
                    key={key}
                  >
                    <input
                      id={key}
                      name={key}
                      type="checkbox"
                      value={i}
                      onChange={e => this.filter(parseInt(e.target.value), e.target.checked)}
                      checked={checked}
                    />
                    <label
                      htmlFor={key}
                      className={ checked ? "checked" : "" }
                    >
                      <span>{tag}</span>
                    </label>
                  </div>
                );
              }) }
            </div>
            <div className="locations-container__filter__notice-box">
              <div className="locations-container__filter__notice"
                dangerouslySetInnerHTML={{
                  __html: notice
                }}
              />
            </div>
          </div>
          { branches.map((branch, i) => {
              if (!tagFilter.size || tagFilter.has(this.tags.indexOf(branch.tag))) {
                return (
                  <div key={i}>
                    <div className="row">
                      <h1 className="locations-container__title col-12 col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-lg-10 offset-lg-1">
                        {branch.name}
                      </h1>
                    </div>
                    <div className="row">
                      <LocationCards cards={branch.offices} />
                    </div>
                  </div>
                );
              }
              return null;
            })
          }
        </ContentBox>
      </section>
    );
  }

}

export default LocationsContainer;