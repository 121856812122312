import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CTAForm from 'components/CTAForm';
import LocationsContainer from 'components/LocationsContainer/LocationsContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';

const LocationPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.locationYaml.metaTags} title={data.locationYaml.title} />
      <Hero data={data.locationYaml.hero} />
      <LocationsContainer data={data.locationYaml.locationsContainer} />
      <CTAForm />
      <ZipGateContainer location={location} />
      <ScrollToTopButton />
    </Layout>
  );
};

export default LocationPage;

export const pageQuery = graphql`
  query LocationPageQuery {
    locationYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroImagePosition
      }

      locationsContainer {
        notice
        branches {
          name
          tag
          offices {
            officeName
            addressLine1
            addressLine2
            phone {
              title
              link
            }
          }
        }
      }
    }
  }
`;
