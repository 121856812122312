import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import throttle from 'lodash.throttle';
import classnames from 'classnames';
import IconArrowUp from 'static/images/icons/arrow-up.inline.svg';
import './ScrollToTopButton.scss';

class ScrollToTopButton extends Component {
  constructor() {
    super();
    this.state = {
      showButton: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttleScroll, false);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleScroll, false);
  }

  throttleScroll = throttle(() => this.handleScroll(), 50, {
    trailing: true,
    leading: true,
  });

  handleScroll = () => {
    this.setState({
      showButton: window.scrollY > window.innerHeight / 2,
    });
  };

  scrollToTop = () => {
    scroll.scrollToTop({ duration: 500, delay: 0 });
  };

  render() {
    const { showButton } = this.state;
    return (
      <div className={classnames('aaa-scrolltotop', { hidden: !showButton })}>
        <button className="aaa-scrolltotop__button" onClick={this.scrollToTop}>
          <IconArrowUp className="aaa-scrolltotop__icon" />
        </button>
      </div>
    );
  }
}

export default ScrollToTopButton;
